import { useState, createContext, useEffect } from 'react';
import { Routes, Route, useNavigate,Navigate } from 'react-router-dom';
import Homepage from './components/Homepage';
import Dashboard from './components/Dashboard';
import Billionaire from './artifacts/contracts/Billionaire.sol/Billionaire.json';
import { message } from 'antd';
import Teams from './components/Teams';
import BinaryTree from './components/BinaryTree';
import Downline from './components/Downline';
import Test from './components/Test';

import { BrowserProvider, Contract, ethers } from 'ethers';
import { createAppKit,useAppKit,useAppKitProvider, useAppKitAccount,useDisconnect } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { bscTestnet } from '@reown/appkit/networks';

const projectId = '5a38f77861b7ce8119ac054303e8c397';
const networks = [bscTestnet];
const metadata = {
  name: 'Billionaire',
  description: "Let's build your future together!",
  url: 'https://www.billionaire.run', // origin must match your domain & subdomain
  icons: ['https://www.billionaire.run/logo.png']
}
createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId
})

const AppState = createContext();


function App() {
  const navigate = useNavigate();

  // State hooks
  const [theme, setTheme] = useState('dark');
  const [walletAddress, setWalletAddress] = useState('');
  const [id, setId] = useState(0);
  const [shown, setShown] = useState(false);
  const [bal, setBal] = useState(0);
  const [bal2, setBal2] = useState(0);
  const [bal3, setBal3] = useState(0);
  const [levels, setLevels] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [percent, setPercent] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(0);
  const [staticContract, setStaticContract] = useState();
  const [ref, setRef] = useState('');
  const [termsConditions, setTermsConditions] = useState(false);



  const ranks = ["Associate", "Coordinator", "Executive", "Specialist", "Manager", "Director", "Leader", "Commander", "Governor", "Administrator", "Supervisor", "Chairman", "President", "Billionaire"];
  const contractAddress = "0xD12F7cC22C1eB2f9323E15D0c791Db2a3A765e07";

  let provider, signer;

  const modal = useAppKit();
  const { address, isConnected } = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155')
  const { disconnect } = useDisconnect();

  const disconnectwallet =  () => {
     disconnect();
     setTermsConditions(false);
     navigate('/');
  }

  // useEffect(() => {
  //   console.log('Is Connected:', isConnected);
  //   console.log('Wallet Address:', address);
  // }, [isConnected, address]);
  
  useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const refValue = queryParams.get("ref");

      if (refValue) {
          console.log(`Ref parameter found: ${refValue}`);
          if (validateRef(refValue)) {
              //console.log("Ref is valid. Proceeding with login...");
              setRef(refValue);
              //Login(); // Call the login function with the valid refValue
          } else {
              console.error("Invalid ref value:", refValue);
              message.error("Invalid referral ID provided.");
          }
      }
  }, []);

  useEffect(() => {
      if (ref) {
          //console.log("State updated with ref:", ref);
          Login(); // Call Login using the updated state
      }
  }, [ref]);

  const validateRef = (ref) => {
      // Ensure "ref" is a non-empty numeric string and within a specific range (example)
      const isNumeric = /^\d+$/.test(ref); // Regex to check if ref is numeric
      const isValidLength = ref.length > 0 && ref.length <= 10; // Example: Length check
      return isNumeric && isValidLength;
  };

  // Get Ethereum provider and signer
  const getProvider = async () => {
    if (!isConnected) throw Error('User disconnected')
    provider = new BrowserProvider(walletProvider);
    signer = await provider.getSigner();
  };

  // Get Contract instances
  const getContract = async () => {
    await getProvider();
    // return new ethers.Contract(contractAddress, Billionaire.abi, signer);
    return new Contract(contractAddress, Billionaire.abi, signer);
  };

  // Handle Theme Toggle
  const toggle = () => {
    setTheme(prevTheme => {
      const newTheme = prevTheme === 'dark' ? 'light' : 'dark';
      localStorage.setItem('theme', newTheme);
      return newTheme;
    });
  };

  // Fetch user wallet address and contract data
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);

  // Fetch contract levels, user id and balances
  useEffect(() => {
    const fetchLevelsAndBalances = async () => {
      try {
        if (isConnected) {
          await getProvider();
          const contract = await getContract();
              
          // Fetch the levels and percents
          const _lvl = await contract.getLevels();
          
          // console.log("Levels response:", _lvl); // Check the output
          setLevels([0])
          for(let i=0; i<_lvl[0].length; i++) {
            setLevels((prev) => [...prev, Number(ethers.formatEther(_lvl[0][i]))]);
          }
          setPercent([0]);
          for(let i=0; i<_lvl[1].length; i++) {
            setPercent((prev) => [...prev, Number(_lvl[1][i])]);
          }

          // Fetch user balances and id
          const userId = await contract.id(walletAddress);
          setId(Number(userId));

          const _bal = await provider.getBalance(walletAddress);
      
          setBal(ethers.formatEther(_bal));
      
          setLoaded(true);
          
        }else{
          console.log('Please install MetaMask to interact with the contract.');
          message.error("Please install MetaMask to interact with the contract.");
        }
    
        
      } catch (error) {
        console.error("Error fetching contract data:", error);
        message.error("Failed to fetch contract data. Please check your contract and connection.");
        
      }
    };
    
  
    if (isConnected) {
      fetchLevelsAndBalances();
    }

  }, [walletAddress, change]);

  const TermCondition = async () => {
    setTermsConditions(true);
  }
   
  // Login function
  const Login = async () => {
    try {
      if (!isConnected) {
        try {
          await modal.open();
        } catch (err) {
          message.error("Error opening wallet modal");
          console.error("Error opening wallet modal:", err);
        }
      }

      if (isConnected){
        if (address) {
          console.log("Wallet Address: ", address);
          //message.success("Wallet Address: ", address);
        } else {
          console.error("Address not found. Ensure wallet is connected.");
          message.error("Address not found. Ensure wallet is connected.");
        }

        setWalletAddress(address);

        const contract = await getContract();
        setStaticContract(contract);

        if(termsConditions){
          const userId = await contract.id(address);
          setId(Number(userId));
          
            if (userId == 0) {
              console.log('User Not Registered');
              message.error("User Not Registered");
              if (!ref || ref.trim() === "") {
                  message.error("Referred ID Missing");
                  return;
              }
          
              try {
                setLoading(true);
                console.log("Attempting registration...");
                message.error("Attempting registration...");
                
                // Convert BNB amount to wei (for value, not gas price)
                const bnbAmount = 0.00043; // BNB amount (transaction value)
                const weiAmount = ethers.parseUnits(bnbAmount.toString(), "ether");
                console.log("Transaction value in wei:", weiAmount.toString()); // Outputs: "430000000000000"
            
                // Transaction execution
                const tx = await contract.register(ref, address, {
                    gasLimit: 3000000, // Add buffer to avoid edge case failures
                    value: weiAmount, // Add BNB amount to the transaction if required
                });
            
                console.log("Transaction sent. TX Hash:", tx.hash);
            
                // Wait for transaction confirmation
                const receipt = await tx.wait();
                console.log("Transaction successful. Receipt:", receipt);
            
                // Fetch User ID and update state
                console.log("Fetching User ID...");
                const userId = await contract.id(address);
                setId(Number(userId));
                message.success("Sucessfully Registered");
                message.success("Redirecting to dashboard...");
                console.log("Redirecting to dashboard...");
                setLoading(false);
                navigate("/dashboard");
              } catch (error) {
                  setLoading(false);
                  console.error("An error occurred during registration:", error);
              
                  // Show appropriate error message
                  if (error.code === "INSUFFICIENT_FUNDS") {
                      message.error("Insufficient funds for gas or transaction value.");
                  } else if (error.code === "CALL_EXCEPTION") {
                      message.error("Transaction failed. Please check the Referrer id in the link");
                  }
                  else {
                      message.error("An error occurred. Please try again later.");
                  }
              }
          
            } else {
                //console.log('User already registered. Redirecting to dashboard...');
                navigate('/dashboard');
            }
        }

      }
 
    } catch (error) {
      message.error("Install Web3 Wallet");
    }
  };

  // Format Time
  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
    return `${days.toString().padStart(2, '0')} Days, ${hr.toString().padStart(2, '0')} : ${min.toString().padStart(2, '0')} : ${sec.toString().padStart(2, '0')}`;
  };

  const convert = (val) => {
    return Number(ethers.formatEther(val));
  }

  // Get UTC Time
  const getUTCTime = (ms) => {
    const now = new Date(ms);
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1;
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} UTC`;
  };
  

  return (
    <AppState.Provider value={{
      formatTime,
      getUTCTime,
      convert,
      loaded,
      loading,
      setLoading,
      bal,
      bal2,
      bal3,
      id,
      navigate,
      percent,
      levels,
      ranks,
      toggle,
      getContract,
      setStaticContract,
      shown, 
      setShown, 
      theme,
      Login,
      walletAddress,
      change,
      setChange,
      staticContract,
      contractAddress,
      disconnectwallet,
      isConnected,
      termsConditions,
      TermCondition
    }}>
      <div className={theme}>
        <div className="App">
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/team' element={<Teams />} />
            <Route path='/matrix' element={<BinaryTree />} />
            <Route path='/downline' element={<Downline />} />
            <Route path='/test' element={<Test />} />
            <Route path='*' element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </AppState.Provider>
  );
}

export default App;
export { AppState };
